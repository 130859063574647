import * as React from "react"
import {Helmet} from "react-helmet";
import {Link, graphql} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {OutboundLink} from "gatsby-plugin-google-gtag"

import "../styles/index.scss";

const SinglePage = ({data}) => {
    const item = data.collectionYaml.collection;
    const series = data.allSeriesYaml.nodes.map(row => row.series).filter((series) => series.id === item.series_id)[0];
    const image = getImage(item.image);
    return (
        <main id={"page"}>
            <Helmet>
                <title>Wagyu No. {`${item.id}`} - The Wagyu Gallery</title>
            </Helmet>
            <h1 id={"webtitle"}>
                The Wagyu Gallery
            </h1>
            <div className={"single-wrapper"}>
                <div className={"single-image"}>
                    <OutboundLink href={`https://solsea.io/nft/${item.solana}`} target={"_blank"} rel={"noreferrer"}>
                        <GatsbyImage
                            image={image}
                            alt={item.short_description}
                        />
                    </OutboundLink>
                </div>
                <div className={"single-metadata"}>
                    <Link to={"/"} className={"action-back"}>
                        <span className={"action-back"}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 17l-5-5m0 0l5-5m-5 5h12"/>
                            </svg>
                        </span> Back
                    </Link>

                    <h2>Wagyu No. {item.id}</h2>
                    <h3>{series.name}</h3>
                    <div className={"description"}>
                        {item.full_description}
                    </div>
                    <div className={"meta"}>
                        <span>Last listed price: </span>
                        <span>1</span>
                        <span> </span>
                        <span>SOL</span>
                    </div>

                    <div className={"link"}>
                        <OutboundLink href={`https://solsea.io/nft/${item.solana}`} target={"_blank"} rel={"noreferrer"}>
                            View on Solsea
                        </OutboundLink>
                        <OutboundLink href={`https://explorer.solana.com/address/${item.solana}`} target={"_blank"} rel={"noreferrer"}>
                            View on Solana Explorer
                        </OutboundLink>
                        <OutboundLink href={`https://cloudflare-ipfs.com/ipfs/${item.ipfs}`} target={"_blank"} rel={"noreferrer"}>
                            View on IPFS
                        </OutboundLink>
                        <OutboundLink href={`/arts/wagyu_${item.id}.jpg`}>
                            View Full Image
                        </OutboundLink>
                    </div>
                </div>
            </div>
            <footer>
                <div>
                    Copyright © 2021 The Wagyu Gallery. All rights reserved.
                </div>
                <div>
                    <OutboundLink href={"https://solsea.io/collection/614dbceff0a1075fd4c8a1de"}>Solsea</OutboundLink> | <OutboundLink href={"https://twitter.com/WagyuGallery"}>Twitter</OutboundLink>
                </div>
            </footer>
        </main>
    )
}

export default SinglePage;

export const query = graphql`
query($collection__id: Int!) {
    collectionYaml(collection: {id: {eq: $collection__id}}) {
        collection {
            id
            series_id
            short_description
            full_description
            solana
            ipfs
            image {
                childImageSharp {
                    gatsbyImageData(width: 640)
                }
            }
        }
    }
    allSeriesYaml {
        nodes {
            series {
                id
                name
            }
        }
    }
}
`